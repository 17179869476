import React, { useRef, useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    width: '130px !important',
    '& .MuiIconButton-root': {
      padding: 4,
    },
  },
  input: {
    padding: '8px 4px',
    width: 50,
    textAlign: 'center',
    '-moz-appearance': 'textfield',
    '&::-webkit-inner-spin-button, ::-webkit-outer-spin-button': {
      appearance: 'none',
    },
  },
}));

const QuantityInput = ({ value, onChange }) => {
  const classes = useStyles();
  const ref = useRef();
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    if (value === inputValue) return;
    setInputValue(+value);
  }, [value]);

  const incr = () => {
    const newValue = Number(inputValue) + 1;
    onChange(newValue);
    setInputValue(newValue);
  };
  const decr = () => {
    if (inputValue > 1) {
      const newValue = Number(inputValue) - 1;
      onChange(newValue);
      setInputValue(newValue);
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    if (value > 0) {
      setInputValue(+value);
    }
  };

  const onBlue = () => {
    onChange(inputValue);
  };
  return (
    <TextField
      inputRef={ref}
      className={classes.root}
      type="number"
      value={inputValue}
      onBlur={onBlue}
      onChange={handleChange}
      margin="none"
      classes={{
        input: classes.input,
      }}
      startAdornment={
        <InputAdornment position="start">
          <IconButton onClick={decr}>
            <RemoveIcon />
          </IconButton>
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
          <IconButton onClick={incr}>
            <AddIcon />
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

export default QuantityInput;
