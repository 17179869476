import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Input from './Input';

FormikPhoneNumberInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  inputRef: PropTypes.func.isRequired,
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.formattedValue,
          },
        });
      }}
      format="###-###-####"
    />
  );
}

function FormikPhoneNumberInput(props) {
  // const { inputRef, onChange, name, ...other } = props;

  return (
    <Input inputComponent={NumberFormatCustom} {...props} />
  )
}

export default FormikPhoneNumberInput;
