import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { money } from 'utils';
import { updateGlobalOption } from 'store/actions/cart';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  option: {
    border: '1px solid silver',
    padding: 4,
    marginTop: 8,
    marginLeft: 0,
    borderRadius: 4,
  },
  req: {
    '&::after': {
      content: '"Choice Required"',
      color: 'red',
      paddingLeft: 8,
      fontSize: 14,
    },
  },
});

const GlobalOptions = ({}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { globalOptions } = useSelector((s) => s.lab);
  const { orderOptions = {} } = useSelector((s) => s.cart);

  const addOrderOptions = (e, optionId) => {
    const { value } = e.target;
    dispatch(
      updateGlobalOption({
        [optionId]: value,
      })
    );
  };

  return (
    <Box>
      {globalOptions.global_checkout_options.map((option) => {
        const required = option.required;
        const options = option.choices.map((i) => {
          const pcontotal = i.pcontotal
            ? ` + ${i.pcontotal}% of cart total`
            : '';
          const label =
            i.label +
            ' - ' +
            money(i.price, globalOptions.currency) +
            (i.priceperfile ? ' per file' : '') +
            pcontotal;

          return (
            <FormControlLabel
              key={i.id}
              value={i.id}
              control={
                <Radio
                  color="primary"
                  size="small"
                />
              }
              label={label}
              className={classes.option}
            />
          );
        });

        return (
          <Box key={option.id} mb={3}>
            <Typography variant="h6" className={required ? classes.req : ''}>
              {option.description}
            </Typography>

            <FormControl>
              <RadioGroup
                name={option.id}
                value={orderOptions[option.id]}
                onChange={(e) => addOrderOptions(e, option.id)}
              >
                <FormLabel>
                  <Typography color="textPrimary">{option.subtitle}</Typography>
                </FormLabel>
                {options}
              </RadioGroup>
            </FormControl>
          </Box>
        );
      })}
    </Box>
  );
};

export default GlobalOptions;
