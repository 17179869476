import axios from 'axios';
import axiosRetry from 'axios-retry';
import * as Sentry from '@sentry/react';

axiosRetry(axios, { retries: 1 });

const errorResponse = (err) => {
  if (err.response?.data) {
    return Promise.reject(err.response.data);
  } else {
    return Promise.reject(err);
  }
};

export const adminClient = axios.create({
  baseURL: process.env.REACT_ADMIN_API,
});

const sosClient = axios.create({
  baseURL: '/api',
});

adminClient.interceptors.response.use((resp) => resp.data, errorResponse);
sosClient.interceptors.response.use((resp) => resp.data, errorResponse);

export const uploadImage = async (
  file,
  fileKey,
  folderName,
  onUploadProgress
) => {
  const { url, key } = await sosClient.post('cart/signed-url', {
    type: file.type,
    name: fileKey,
    folder: folderName,
  });

  await axios.put(url, file, {
    'axios-retry': {
      retries: 4,
      onRetry: (retryCount, error) => {
        console.log('axios-retry is retrying...');
        Sentry.captureMessage(
          `axios-retry is retrying (retry: ${retryCount}) - error: ${error}`
        );
      },
    },
    headers: {
      'Content-Type': file.type,
    },
    onUploadProgress,
  });
  return { key };
};

export const calculatePrice = (data) => {
  return sosClient.post('cart/price', data);
};

export const createCart = (data) => sosClient.post('cart', data);

export const createOrder = (data) => sosClient.post('orders', data);

export const parseAddress = (text) =>
  axios
    .post(
      'https://qjn81caq90.execute-api.us-west-2.amazonaws.com/prod/parse-address',
      text
    )
    .then((resp) => resp.data);

export const recoverAccountId = (email) =>
  sosClient.post('cart/recover-account', { email });
