import { useSelector } from 'react-redux';
import Lockets from '../home/custom/Lockets';
import ClassicHome from './ClassicHome';

const Home = () => {
  const lab = useSelector((s) => s.lab);
  if (lab.lab_id === 'lockets') {
    return <Lockets />;
  } else {
    return <ClassicHome />;
  }
};
export default Home;
