import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import Rotate90DegreesCcwIcon from '@material-ui/icons/Rotate90DegreesCcw';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: '8px 4px',
    minWidth: 50,
    padding: '4px 8px',
  },
  middleButton: {
    paddingLeft: 0,
    paddingRight: 0,
    minWidth: 40,
    margin: '8px 4px',
  },
  input: {
    display: 'none',
  },
}));

const RotateSettings = ({ onRotate, onReplaceImage, disabled }) => {
  const classes = useStyles();

  const handleRotate = (event, newValue) => {
    onRotate(newValue);
  };

  const onLeft = () => {
    handleRotate(null, -90);
  };

  const onRight = () => {
    handleRotate(null, 90);
  };

  const onLeftSmall = () => {
    handleRotate(null, -1);
  };

  const onRightSmall = () => {
    handleRotate(null, 1);
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Tooltip arrow title="Rotate left by 90 degrees">
          <Button
            disableRipple
            disabled={disabled}
            className={classes.button}
            size="small"
            variant="contained"
            onClick={onLeft}
            startIcon={<Rotate90DegreesCcwIcon />}
          >
            90°
          </Button>
        </Tooltip>
        <Tooltip arrow title="Rotate left by 1 degree">
          <Button
            disableRipple
            disabled={disabled}
            className={classes.button}
            size="small"
            variant="contained"
            onClick={onLeftSmall}
            startIcon={<RotateLeftIcon />}
          >
            1°
          </Button>
        </Tooltip>
        {onReplaceImage && (
          <>
            <label htmlFor="button-replace-file">
              <Tooltip arrow title="Change Image">
                <Button
                  disableRipple
                  disabled={disabled}
                  component="span"
                  size="small"
                  variant="contained"
                  className={classes.middleButton}
                >
                  <AddPhotoAlternateIcon />
                </Button>
              </Tooltip>
            </label>
            <input
              disabled={disabled}
              onChange={onReplaceImage}
              accept="image/png, image/jpeg"
              className={classes.input}
              id="button-replace-file"
              type="file"
            />
          </>
        )}
        <Tooltip arrow title="Rotate right by 1 degree">
          <Button
            disableRipple
            disabled={disabled}
            className={classes.button}
            size="small"
            variant="contained"
            onClick={onRightSmall}
            startIcon={<RotateRightIcon />}
          >
            1°
          </Button>
        </Tooltip>
        <Tooltip arrow title="Rotate right by 90 degrees">
          <Button
            disabled={disabled}
            className={classes.button}
            size="small"
            variant="contained"
            onClick={onRight}
            startIcon={
              <Rotate90DegreesCcwIcon
                style={{ transform: 'rotateY(180deg)' }}
              />
            }
          >
            90°
          </Button>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default RotateSettings;
