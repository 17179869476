import Queue from 'utils/queue';
import * as api from 'utils/api';
import { getFileHash } from 'utils';
import * as Sentry from '@sentry/react';
import uniq from 'lodash/uniq';

const queue = new Queue();

export const ACTIONS = {
  ADD_TO_GALLERY: 'ADD_TO_GALLERY',
  ADD_TO_CART: 'ADD_TO_CART',
  REMOVE_FROM_CART: 'REMOVE_FROM_CART',
  UPLOAD: 'UPLOAD',
  PLACE_ORDER: 'PLACE_ORDER',
  PLACE_ORDER_SUCCESS: 'PLACE_ORDER_SUCCESS',
  PLACE_ORDER_ERROR: 'PLACE_ORDER_ERROR',
  UPDATE_SHIPPING_METHOD: 'UPDATE_SHIPPING_METHOD',
  UPDATE_GLOBAL_OPTION: 'UPDATE_GLOBAL_OPTION',
  UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
  UPDATE_SHIPPING_ADDRESS: 'UPDATE_SHIPPING_ADDRESS',
  UPDATE_ITEM: 'UPDATE_ITEM',
  UPDATE_CART: 'UPDATE_CART',
  CLEAR_CART: 'CLEAR_CART',
  CALC_PRICE: 'CALC_PRICE',
  CALCULATING_PRICE: 'CALCULATING_PRICE',
  CREATE_ORDER: 'CREATE_ORDER',
  CLEAR_GALLERY: 'CLEAR_GALLERY',
  RESET: 'RESET',
  RESTORE: 'RESTORE',
};

export const addToCart = (item) => ({
  type: ACTIONS.ADD_TO_CART,
  payload: item,
});

export const removeFromCart = (item) => {
  Sentry.captureMessage('Item removed from cart');
  return (dispatch, getState) => {
    dispatch({
      type: ACTIONS.REMOVE_FROM_CART,
      payload: {
        item,
      },
    });
    const { items, sessionId } = getState().cart;
    const imageUsed = items.some((el) =>
      el.product.template.nodes.some((n) => n.fileHash === item.fileHash)
    );
    if (!imageUsed) {
      const uploadKey = sessionId + item.fileHash;
      queue.removeItem(uploadKey);
    }
  };
};

export const setShippingMethod = (method) => ({
  type: ACTIONS.UPDATE_SHIPPING_METHOD,
  payload: method,
});

export const updateGlobalOption = (option) => ({
  type: ACTIONS.UPDATE_GLOBAL_OPTION,
  payload: option,
});

export const updateCustomer = (customer) => ({
  type: ACTIONS.UPDATE_CUSTOMER,
  payload: customer,
});

export const updateAddress = (address) => ({
  type: ACTIONS.UPDATE_SHIPPING_ADDRESS,
  payload: address,
});

export const updateItem = (item) => {
  return {
    type: ACTIONS.UPDATE_ITEM,
    payload: item,
  };
};

export const updateCart = (data) => ({
  type: ACTIONS.UPDATE_CART,
  payload: data,
});

export const clearCart = () => {
  Sentry.captureMessage('Clear Cart button clicked');
  queue.clear();
  return {
    type: ACTIONS.CLEAR_CART,
  };
};

export const calculatePrice = () => {
  return (dispatch, getState) => {
    dispatch({
      type: ACTIONS.CALCULATING_PRICE,
    });
    const cart = getState().cart;
    const payload = {
      orderOptions: cart.orderOptions,
      userId: cart.customer.userId,
      isTaxExemptConfirmed: cart.isTaxExemptConfirmed,
    };
    const hashes = [];
    payload.lineItems = cart.items.map((item) => {
      item.product.template.nodes.forEach(n => {
        if (n.fileHash) {
          hashes.push(n.fileHash);
        }
      });
      return {
        id: item.id,
        productId: item.product.id,
        quantity: +item.quantity,
        options: item.options,
      }
    });
    payload.imagesCount = uniq(hashes).length;

    if (cart.shippingMethod) {
      payload.shipmethodId = cart.shippingMethod.id;
      if (cart.shippingMethod.shiptype === 'dropship') {
        payload.address = cart.shippingAddress;
      } else {
        payload.address = cart.customer;
      }
    }

    api.calculatePrice(payload).then((resp) =>
      dispatch({
        type: ACTIONS.CALC_PRICE,
        payload: resp,
      })
    ).catch(
      (e) => {
        Sentry.captureException(e);
      }
    );
  };
};

export const reset = () => ({
  type: ACTIONS.RESET,
});

export const uploadItem = (file, retry = false) => {
  return async (dispatch, getState) => {
    const { uploads, sessionId } = getState().cart;

    const hash = getFileHash(file);
    const uploadKey = sessionId + hash;

    if (uploads[uploadKey] && !retry) return;

    dispatch({
      type: ACTIONS.UPLOAD,
      payload: {
        hash,
        file,
        uploadKey,
        filename: file.name,
        uploadProgress: 0,
        error: null,
        uploading: false,
      },
    });

    queue.enqueue(uploadKey, async () => {
      try {
        const { key } = await api.uploadImage(file, hash, sessionId, (e) => {
          const percentCompleted = Math.round((e.loaded * 100) / e.total);
          dispatch({
            type: ACTIONS.UPLOAD,
            payload: {
              filename: file.name,
              uploadProgress: percentCompleted,
              uploading: true,
              file,
              hash,
              uploadKey,
            },
          });
        });

        dispatch({
          type: ACTIONS.UPLOAD,
          payload: {
            filename: file.name,
            uploadProgress: 100,
            uploading: false,
            key,
            hash,
            uploadKey,
          },
        });
      } catch (e) {
        console.log(`error in try catch - store/actions/cart.js upload ${e}`);
        Sentry.captureException(`Upload error: ${e.message}`);
        dispatch({
          type: ACTIONS.UPLOAD,
          payload: {
            file,
            hash,
            uploadKey,
            filename: file.name,
            error: e.message,
            uploading: false,
          },
        });
      }
    });
  };
};

export const restoreCart = (cart) => ({
  type: ACTIONS.RESTORE,
  payload: cart
});