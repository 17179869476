import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import heartImage from 'images/lockets/heart-shape-locket-print.png';
import ovalImage from 'images/lockets/oval-shape-locket-print.png';
import circleImage from 'images/lockets/circle-shape-locket-print.png';
import squareImage from 'images/lockets/square-locket-print.png';
import rectangleImage from 'images/lockets/rectangle-locket-print.png';
import locketImage from 'images/lockets/locket-photo-print-heart-shape.jpg';

const devEnvironment = process.env.RAZZLE_APP_STATUS;

const useStyles = makeStyles((theme) => ({
  imageGrid: {
    maxWidth: '140px',
    textAlign: 'center',
    margin: '.7rem 1rem',
    borderRadius: '4px',
    outline: ' 1px solid #afafaf',
    '& a': {
      color: '#000',
      textDecoration: 'none',
    },
    '& img': {
      padding: '.5rem .5rem 0 .5rem',
      maxWidth: '130px',
      height: 'auto',
      objectFit: 'contain',
    },
  },

  banner: {
    maxWidth: '100%',
    height: 'auto',
    objectFit: 'contain',
  },
  container: {
    height: '100%',
  },
  button: {
    padding: '.5rem',
    fontSize: '1rem',
    borderRadius: '4px',
    backgroundColor: '#ef6c00',
    color: '#fff',
    margin: '.25rem auto',
    maxWidth: '120px',
  },
  grid: {
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
}));

export default function Lockets() {
  const classes = useStyles();
  const locketImages = [
    {
      shape: 'Heart',
      image: heartImage,
      url: '/editor/locket-prints/HJkbmDUs_',
    },
    { shape: 'Oval', image: ovalImage, url: '/editor/locket-prints/H1UQ7DLsO' },
    {
      shape: 'Circle',
      image: circleImage,
      url: '/editor/locket-prints/rkfM7D8sd',
    },
    {
      shape: 'Square',
      image: squareImage,
      url: '/editor/locket-prints/gFF8jskUc',
    },
    {
      shape: 'Rectangle',
      image: rectangleImage,
      url: '/editor/locket-prints/HkoJXP8o_',
    },
  ];

  return (
    <Box marginTop={2} marginBottom={3}>
      <Helmet title="Locket Print Ordering System">
        <meta
          name="description"
          content="Order photos to fit in your locket, heart, oval, circle, square and rectangular shapes available."
        />
      </Helmet>
      <Container fixed className={classes.container}>
        <Typography variant="h1">Locket Print Ordering System</Typography>
        <Typography>
          Order prints to fit any sized locket. Our locket prints include 25+
          different sizes of your image on a 5x7, one or more of which should
          fit any normal sized locket up to 1.5 inches.
        </Typography>

        <Typography variant="h4">Choose your Locket Shape</Typography>
        <Typography>
          Choose a locket shape below to get started. You can zoom in and rotate
          as needed.
        </Typography>
        <Box marginTop={3} marginBottom={3}>
          <Grid container spacing={1} className={classes.grid}>
            {locketImages.map((image, index) => (
              <Grid
                item
                xs={6}
                sm={6}
                md={3}
                lg={2}
                xl={2}
                key={index}
                className={classes.imageGrid}
              >
                <Link to={image.url} className={classes.item}>
                  <img src={image.image} alt={`Locket ${index + 1}`} />
                  <Box className={classes.button}>{image.shape} </Box>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Typography paragraph>Locket prints are $8.95 each.</Typography>
        <Typography>
          Shipping options for email, USPS and Next Day UPS.
        </Typography>
        <Box marginTop={6} marginBottom={6}>
          <Typography variant="h2">FAQ's</Typography>
          <hr />
          <Typography variant="h6">
            How do I know what size to order?
          </Typography>
          <Typography variant="body2">
            You don't need to tell us the size of your locket. We will included
            25+ different sizes of your image on a 5x7, one or more of which
            should fit any normal sized locket up to 1.5 inches.
          </Typography>
          <Typography variant="h6">
            What size locket will this locket photo fit?
          </Typography>
          <Typography variant="body2">
            We'll give you a print with sizes from about 3/8" to 1.5" or about
            9.5 mm up to 3.9 cm.
          </Typography>
          <Typography variant="h6">
            What if the shape is not exactly the same as my locket?
          </Typography>
          <Typography variant="body2">
            Great question, we have no way to know the exact shape of your
            locket, if you order a heart, oval or circle shape we will send you
            a print with the shape overlay and another print without it - so if
            the heart shape is not right you'll still have options without the
            shape outline that you can use.
          </Typography>
        </Box>
        <Box my={4}>
          <Typography variant="h2">
            What Does a Locket Print Look Like?
          </Typography>
          <hr />
          <Typography>
            Here is an example of a heart shaped locket print, this is a 5x7
            print with over 25 different sized versions of the same image.
          </Typography>
        </Box>
        <Box textAlign="center" mb={8}>
          <img
            className={classes.banner}
            src={locketImage}
            alt="Locket Prints"
          />
        </Box>
      </Container>
    </Box>
  );
}
