import {
  AppBar,
  Container,
  Grid,
  makeStyles,
  Toolbar,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import React, { useContext } from 'react';
import Badge from '@material-ui/core/Badge';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    borderBottom: '1px solid #dcdcdc',
    marginBottom: '0rem',
  },
  navLogo: {
    [theme.breakpoints.down('sm')]: {
      margin: '10px auto 0',
    },
  },
  navLogoImage: {
    [theme.breakpoints.down('sm')]: {
      maxHeight: 30,
    },
    maxHeight: 50,
  },
  navLinks: {
    paddingTop: '10px',
    [theme.breakpoints.down('sm')]: {
      margin: '8px auto 10px',
    },
  },
  navLink: {
    [theme.breakpoints.down('sm')]: {
      height: 48,
    },
    textDecoration: 'none',
    fontSize: '1rem',
    fontWeight: 'bold',
    color: '#555',
    marginRight: '.8rem',
    '&:hover': {
      color: [theme.palette.secondary.light],
    },
  },
  lastNavLink: { marginRight: 0 },
  navItem: {},
  active: {
    color: [theme.palette.secondary.main],
  },
}));

export default function Navbar() {
  const location = useLocation();
  const items = useSelector((s) => s.cart.items);
  const lab = useSelector((s) => s.lab);
  const MenuItems = [
    {
      text: 'Home',
      path: '/',
    },
    {
      text: 'Help',
      path: '/help',
    },
    {
      text: 'Contact',
      path: '/contact',
    },
    {
      render: () => (
        <Badge max={9999} badgeContent={items.length} color="secondary">
          <Link
            to="/cart"
            key="Cart"
            className={`${classes.navLink} ${classes.lastNavLink} ${
              location.pathname === '/cart' ? classes.active : null
            }`}
          >
            Cart
          </Link>
        </Badge>
      ),
    },
  ];
  const classes = useStyles();

  return (
    <AppBar position="relative" className={classes.root} elevation={0}>
      <Container fixed>
        <Toolbar disableGutters>
          <Grid container justifyContent="space-between">
            <Grid item key="logo" className={classes.navLogo}>
              <Link to="/">
                <img
                  className={classes.navLogoImage}
                  src={lab.lab_logo}
                  alt={lab.lab_name}
                />
              </Link>
            </Grid>
            <Grid item key="links" className={classes.navLinks}>
              <Grid container justifyContent="center">
                {MenuItems.map((item, idx) => (
                  <Grid item className={classes.navLink} key={idx}>
                    {item.render ? (
                      item.render()
                    ) : (
                      <Link
                        to={item.path}
                        key={item.text}
                        className={`${classes.navLink} ${
                          location.pathname === item.path
                            ? classes.active
                            : null
                        }`}
                      >
                        {item.text}
                      </Link>
                    )}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
