import { useState, useEffect } from 'react';
import noop from 'lodash/noop';
import { getFromCache, setInCache } from 'utils/img-cache';
import { getFileHash } from 'utils';
import fileIcon from 'images/download.png';

let g;

const getImageDimensions = (file) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const objectUrl = URL.createObjectURL(file);
    img.onload = () =>
      resolve({
        width: img.width,
        height: img.height,
      });
    img.onerror = (error) => ({
      width: 0,
      height: 0,
    });
    img.onerror = (error) => reject(error);
    img.src = objectUrl;
  });
};

export async function genThumb(file, options) {
  const { skipResolution, maxSize } = options;
  if (skipResolution) {
    const { width, height } = await getImageDimensions(file);
    if (width > skipResolution || height > skipResolution) {
      return;
    }
  }
  const img = document.createElement('img');
  if (!g) {
    const fineUpload = require('fine-uploader');
    g = new fineUpload.ImageGenerator(noop);
  }
  const r = await g.generate(file, img, { maxSize: maxSize ?? 100 });
  return r.src;
}

export default function useThumbnail(file, showBatchThumbnail, options = {}) {
  const [thumb, setThumb] = useState(fileIcon);

  useEffect(() => {
    (function () {
      if (!file) return;
      const id = getFileHash(file);
      const cached = getFromCache(id);
      if (cached) {
        setThumb(cached);
      } else {
        if (!showBatchThumbnail) return;
        genThumb(file, options).then((src) => {
          if (src) {
            setInCache(id, src);
            setThumb(src);
          }
        });
      }
    })();
  }, []);
  return thumb;
}
