import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Prompt } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import { useSnackbar } from 'notistack';
import { createOrder } from 'utils/api';
import { useDispatch, useSelector } from 'react-redux';
import { reset } from 'store/actions/cart';
import Spin from 'components/Spin';
import { AlertTitle } from '@material-ui/lab';
import { uploadItem } from 'store/actions/cart';
import uniq from 'lodash/uniq';
import useWakeLock from 'hooks/useWakeLock';

const useStyles = makeStyles((theme) => ({
  info: {
    margin: '16px 0',
  },
  list: {
    maxHeight: 400,
    overflowY: 'auto',
    border: '10px solid #efefef',
    padding: '20px',
    marginTop: 20,
  },
}));

const Upload = ({ history }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState();
  const dispatch = useDispatch();
  const cart = useSelector((s) => s.cart);
  const uploads = useSelector((s) => s.cart.uploads);
  const [order, setOrder] = useState();
  // get cookie named refer
  const referCookie = document.cookie
    .split('; ')
    .find((row) => row.startsWith('qref'));
  const referCookieDateSet = document.cookie
    .split('; ')
    .find((row) => row.startsWith('qrefd'));
  const gclid = document.cookie
    .split('; ')
    .find((row) => row.startsWith('gclid'));
  const gclid2 = gclid ? gclid.split('=')[1] : '-';

  const refer = referCookie
    ? {
        referer: referCookie.split('=')[1],
        refererDateSet: referCookieDateSet?.split('=')[1],
        width: window.innerWidth,
        height: window.innerHeight,
        gclid: gclid2,
      }
    : null;
  useWakeLock();

  useEffect(async () => {
    if (!cart.items.length || !cart.cartId) {
      history.replace('/');
    } else {
      const hashes = [];
      cart.items.forEach((item) => {
        item.product.template.nodes.forEach((node) => {
          if (node.fileHash) {
            hashes.push(cart.sessionId + node.fileHash);
          }
        });
      });
      const itemFiles = uniq(hashes);
      const allUploaded = !itemFiles.some(
        (uploadKey) => !uploads[uploadKey].key
      );

      if (allUploaded) {
        try {
          const resp = await createOrder({
            cartId: cart.cartId,
            refer,
          });
          setOrder(resp);
          enqueueSnackbar('Order created', { variant: 'success' });
          // log screen size and browser
          console.log(
            'navigator.maxTouchPoints >>',
            window.navigator.maxTouchPoints
          );
          console.log(
            'inner width and height: ',
            window.innerWidth,
            window.innerHeight
          );
          console.log(
            `Order Number: ${resp.orderId}, user agent: ${window.navigator.userAgent}`
          );
          setTimeout(() => {
            dispatch(reset());
          }, 0);
          Sentry.captureMessage('SOSV2 order created');
          history.replace({
            pathname: '/success',
            state: { orderId: resp.orderId, order: resp },
          });
        } catch (e) {
          Sentry.captureException(e.message);
          setError(e.message);
        }
      }
    }
  }, [cart]);

  const onRetry = (e, uploadKey) => {
    e.preventDefault();
    Sentry.captureMessage('Retry upload link clicked');
    const item = uploads[uploadKey];
    dispatch(uploadItem(item.file, true));
  };

  const hashes = [];
  cart.items.forEach((item) => {
    item.product.template.nodes.forEach((node) => {
      if (node.fileHash) {
        hashes.push(cart.sessionId + node.fileHash);
      }
    });
  });
  const itemFiles = uniq(hashes);
  const totalImages = itemFiles.length;
  const completedCount = itemFiles.filter(
    (uploadKey) => uploads[uploadKey].key
  ).length;

  const uploadError =
    itemFiles.filter((uploadKey) => uploads[uploadKey].error).length > 0;

  if (totalImages === 0) {
    Sentry.captureException('0/0 images on upload page');
  }
  return (
    <Container>
      <Helmet title="Uploading Files" />
      <Prompt
        when={!order && totalImages > 0}
        message="Your images are still uploading, please click Cancel to stay on this page and complete your order. If you click OK your order will be lost."
      />
      <Box mt={4} mb={4}>
        <Typography paragraph variant="h1">
          Uploading Images
        </Typography>
        {totalImages === 0 && (
          <Alert severity="error" className={classes.info}>
            <AlertTitle>Something has gone wrong!</AlertTitle>
            This order has failed - your credit card will not be charged. This
            can happen if you manually refresh the page or try to navigate away
            while your images are uploading.
          </Alert>
        )}
        <Typography variant="h4">
          Almost there! Please hang on while we upload your images, this may
          take a little while depending on how many images you have and how fast
          your connection is. Do not close your browser or navigate away from
          this page until it is complete.
        </Typography>
        <Spin
          spinning
          tip={`Please hang on while we upload your images. ${completedCount}/${totalImages} images uploaded...`}
        />
        {totalImages > 10 && (
          <Alert severity="info" className={classes.info}>
            <AlertTitle>Do not close this screen!</AlertTitle>
            Please do not close your browser or navigate away from this page.
            Once the images are all uploaded we will let you know the order is
            complete.
          </Alert>
        )}
        {error && (
          <Box mb={2}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}

        {uploadError && (
          <Box my={2}>
            <Alert severity="error">
              <p>
                It looks like there was an error while uploading one of your
                images, look in the list below and click the Retry button next
                to the one that failed.
              </p>
              <p>
                On top of the occasional network error, this can happen if you
                renamed or moved a file. Files must remain in the same place
                until the order is complete.
              </p>
            </Alert>
          </Box>
        )}
        <Box className={classes.list}>
          {itemFiles.map((uploadKey, index) => (
            <Box key={uploadKey} mb={2}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb={1}
              >
                <Typography variant="subtitle2">
                  {index + 1}) {uploads[uploadKey].filename}{' '}
                  {uploads[uploadKey].key && ' ✅ Uploaded'}
                </Typography>
                <Typography variant="body2" color="textSecondary">{`${
                  uploads[uploadKey].key
                    ? '100'
                    : Math.round(uploads[uploadKey].uploadProgress || 0)
                }%`}</Typography>
              </Box>
              <LinearProgress
                variant="determinate"
                value={uploads[uploadKey].uploadProgress}
              />
              {!uploads[uploadKey].key && (
                <Typography variant="caption" color="error">
                  {uploads[uploadKey].error}{' '}
                  <Link
                    href="#"
                    title="Only click Retry if you think this file is stalled!"
                    onClick={(e) => onRetry(e, uploadKey)}
                  >
                    Retry
                  </Link>
                </Typography>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default Upload;
