import React from 'react';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { useSelector } from 'react-redux';

let qs = [
  {
    q: 'How do I crop my image?',
    a: 'On a small screen (phone, tablet, laptop) you can Spread (2 fingers together, spread them apart) to zoom in and Pinch to zoom out. To move the image around just press and move your finger around. On a regular computer with a mouse the scroll wheel will zoom in/out and then you can click and drag to move the image around. If this does not work on your device please let us know!',
  },
  {
    q: 'What does the Shrink to Fit checkbox do?',
    a: 'When we load an image into the crop box we fill the box with the image so that it does not have any borders. Usually this is what is wanted, though you may lose a little off the top and bottom (or right and left) if your image is not proportional to the size selected. If you need to zoom further out you can click the Shrink to Fit checkbox and the image will zoom all the way out. Due to some quirks with the way Shrink to Fit works if you add an item to the cart with Shrink to Fit checked we will not let you edit it again from the cart.',
  },
  {
    q: "The credit card payment form doesn't work, I can't enter in my card number",
    a: 'If you see the credit card form but you cannot enter in any of the info you probably have something on your device that is blocking our integration with Stripe. Please check and see if you have any browser extensions or add blockers that may block our integration. If the Other - Card on File payment option is available you can use that and we will contact you for payment once we receive the order. If you are still having problems please let us know!',
  },
  {
    q: 'Why are the thumbnail images not showing in the cart?',
    a: 'For performance reasons we do not always show the actual image as the thumbnail in the cart. You can still click the icon and it will pop open a preview of your image (image files only). Please note the thumbnail and the popup preview are your full original file and do not show any cropping you may have done.',
  },
  {
    q: 'How do I edit the cropping when using the Batch Order workflow?',
    a: 'Once you add your images into the cart with the Batch Order button, click on the Pencil/Edit icon on the top row in the cart and you will be taken to the editor where you can zoom in and out and crop as needed. Use the Right Arrow key on your keyboard to load the next image. You can press the Left Arrow key to go to the previous image. When you are done click the Update Cart button.',
  },
];
const locketFaqs = [
  {
    q: 'How do I tell you the size of my locket?',
    a: "You don't need to! Our locket print has over 25 different sizes of your image on a 5x7 sheet ranging from super small up to about 1.5 inches or 40mm, one or more of which will fit any normal size locket - so we don't need to know the size of your specific locket. See the picture on the bottom of the home page to see an example. If your locket is really big (over 1.5 inches or 40mm) please let us know how big it is in the special instructions box and we will make a larger version for you.",
  },
  {
    q: 'Do you ship outside the US?',
    a: 'No, but you can do the email shipping option and we will email you the image files and you can print them on your own (as a 5x7 inch print).',
  },
];

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const FAQS = () => {
  let faqs2 = [];
  const lab = useSelector((s) => s.lab);
  if (lab.lab_id === 'lockets') {
    //combine the two arrays
    faqs2 = [...locketFaqs, ...qs];
  } else {
    faqs2 = qs;
  }
  const [expanded, setExpanded] = React.useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box mt={2}>
      {faqs2.map((item, idx) => (
        <Accordion
          square
          key={idx}
          expanded={expanded === idx}
          onChange={handleChange(idx)}
        >
          <AccordionSummary
            aria-controls={`panel${idx}d-content`}
            id={`panel${idx}d-header`}
          >
            <Typography>{item.q}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{item.a}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default FAQS;
