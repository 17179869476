import { useState, useEffect } from 'react';
import useThumbnail from 'hooks/useThumbnail';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import * as Sentry from '@sentry/react';
import fileIcon from 'images/download.png';

const Image = ({ file, caption, bw }) => {
  const [url, setUrl] = useState(null);
  useEffect(() => {
    const objUrl = URL.createObjectURL(file);
    setUrl(objUrl);
    return () => URL.revokeObjectURL(objUrl);
  }, []);

  return (
    <div
      style={{
        width: 360,
        height: 340,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: 16,
      }}
    >
      <img
        style={{
          maxWidth: 340,
          maxHeight: 300,
          border: '1px solid gray',
          filter: bw ? 'grayscale(100%)' : null,
        }}
        src={url}
      />
      <Typography variant="body2" align="center">
        {caption}
        <br />
        Image preview - cropping not shown
      </Typography>
    </div>
  );
};

const ItemImage = ({ classes, nodes, showBatchThumbnail }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const imgNodes = nodes.filter((n) => n.type === 'image');
  const thumb = useThumbnail(imgNodes[0].image, showBatchThumbnail, {
    skipResolution: 5000,
  });

  const onClick = (e) => {
    setAnchorEl(e.currentTarget);
    Sentry.captureMessage('Cart Thumbnail clicked');
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const files = imgNodes.map((n) => n.filename).join(', ');
  const isWebImage = (file) => {
    if (file === undefined) return false;
    const test =
      file.toLowerCase().includes('.jpg') ||
      file.toLowerCase().includes('.jpeg') ||
      file.toLowerCase().includes('.png');
    return test;
  };

  return (
    <Box className={classes.image} margin="auto">
      <Tooltip title={files}>
        {imgNodes.length === 1 ? (
          <img
            src={thumb}
            onClick={imgNodes[0] && onClick}
            style={imgNodes[0].grayscale ? { filter: 'grayscale(100%)' } : null}
          />
        ) : (
          <img src={fileIcon} onClick={onClick} />
        )}
      </Tooltip>
      <Typography className={classes.caption} component="p" variant="caption">
        {imgNodes.length > 1 ? `${imgNodes.length} Image Nodes` : files}
      </Typography>
      {open && (
        <Popover
          open
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
        >
          <Box p={2}>
            {!isWebImage(imgNodes[0].filename) ? (
              <Typography variant="body2" align="center">
                Preview not available for this file
              </Typography>
            ) : (
              <>
                {imgNodes.map(
                  (i, index) =>
                    i.image && (
                      <Image
                        key={index}
                        file={i.image}
                        caption={i.filename}
                        bw={i.grayscale}
                      />
                    )
                )}
              </>
            )}
          </Box>
        </Popover>
      )}
    </Box>
  );
};

export default ItemImage;
